export class SendDataTTS {
    codigo_accion: string;
    comando: string;
    comando_recibido: string; // el comando recibido
    texto_recibido: string;
    idcomando_voz: number;
    seccion: string;
    texto_default: any; //
    text: string;
    idsede: number;
}
